import React from "react"

import Layout from "../components/layout"
import SecurityBlock from "../components/SecurityBlock/SecurityBlock.js"
import AccordeonBlock from "../components/AccordeonBlock/AccordeonBlock.js"
import ContactBlock from "../components/ContactBlock/ContactBlock"

const IndexPage = () => (
  <Layout>
    <SecurityBlock/>
    <AccordeonBlock/>
    <ContactBlock/>
  </Layout>
)

export default IndexPage
