import { StaticQuery } from "gatsby"
import React from "react"
import styles from "./securityBlock.module.css"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Fragment } from "react";

const SecurityBlock = () => {

  return (
    <StaticQuery
      query={graphql`query SecurityBlock {
  allContentfulSecurityBlock {
    nodes {
      subtitle
      title
      updatedAt
      viewMoreText
      securityLogo {
        fluid(quality: 100) {
          base64
          tracedSVG
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
  }
}

`}
      render={data => {
        return (
          <Fragment>

              <div className={`${styles.securityBlock}`}>
                <div className={styles.mainTitleContainer}>
                  <h1 className={styles.mainTitle}>
                    {data.allContentfulSecurityBlock.nodes[0].title}
                  </h1>
                  <p className={styles.viewMore}>
                    <i className={`${styles.arrowDown} fa fa-arrow-down`} aria-hidden="true"></i>
                    <span className={styles.viewMoreText}>
                      {data.allContentfulSecurityBlock.nodes[0].viewMoreText}
                    </span>
                  </p>
                </div>
                <div className={styles.descriptionContainer}>
                  <div className={styles.textContainer}>
                    <h2 className={styles.descriptionText}>
                      <strong className={styles.bold}>
                        Exeboard
                      </strong>
                      {data.allContentfulSecurityBlock.nodes[0].subtitle}
                    </h2>
                  </div>
                  <div className={styles.logoContainer}>
                    <Img fluid={data.allContentfulSecurityBlock.nodes[0].securityLogo.fluid} />
                  </div>
                </div>
              </div>


              <div className={`${styles.securityBlockDesktop}`}>
                <div className={styles.mainTitleContainerDesktop}>
                  <h1 className={styles.mainTitle}>
                    {data.allContentfulSecurityBlock.nodes[0].title}
                  </h1>
                  <p className={styles.viewMore}>
                    <i className={`${styles.arrowDown} fa fa-arrow-down`} aria-hidden="true"></i>
                    <span className={styles.viewMoreText}>
                      {data.allContentfulSecurityBlock.nodes[0].viewMoreText}
                    </span>
                  </p>
                </div>
                <div className={styles.descriptionContainerDesktop}>
                  <div className={styles.textContainerDesktop}>
                    <h2 className={styles.descriptionTextDesktop}>
                      <strong className={styles.bold}>
                        Exeboard
                      </strong>
                      {data.allContentfulSecurityBlock.nodes[0].subtitle}
                    </h2>
                  </div>
                  <div className={styles.logoContainerDesktop}>
                    <Img fluid={data.allContentfulSecurityBlock.nodes[0].securityLogo.fluid} />
                  </div>
                </div>
              </div>
          </Fragment>


        )
      }
      }
    />
  )
}

SecurityBlock.propTypes = {
}

SecurityBlock.defaultProps = {

}
export default SecurityBlock
