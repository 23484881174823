import { StaticQuery } from "gatsby"
import React from "react"
import styles from "./accordeonBlock.module.css"
import { graphql } from "gatsby"
import AccordeonElement from "../AccordeonElement/AccordeonElement"
import { Fragment } from "react"

const AccordeonBlock = (props) => {

  return (
    <StaticQuery
      query={graphql`query AccordeonBlock {
  allContentfulAccordeonElement(sort:{fields: ordinal}) {
    nodes {
      title
      id
      ordinal
      innerBlocks {
        descriptionListLeft
        descriptionListRight
        title
        icon {
          fluid {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          title
          id
        }
      }
    }
  }
}

`}
      render={data => {
        return (
          <Accordeon data={data} {...props} />
        )
      }
      }
    />
  )
}

class Accordeon extends React.Component {
  constructor(props) {
    super(props)
    // Don't call this.setState() here!
    const elements = props.data.allContentfulAccordeonElement.nodes.map(elm => elm.id)
    this.state = { elements, selected: elements[0] }
  }

  componentDidMount() {
    const elements = this.props.data.allContentfulAccordeonElement.nodes.map(elm => elm.id)
    this.setState({ elements, selected: elements[0] })
  }

  render() {
    const { selected } = this.state

    return (
      <Fragment>
        <div className={styles.accordeonBlock}>
          {
            this.props.data.allContentfulAccordeonElement.nodes.map((elm, i) => {
              return (
                <AccordeonElement
                  key={elm.id}
                  clickBlock={
                  () => {
                    if (typeof document !== "undefined") {
                      var elmnt = document.getElementById(elm.id)
                      elmnt.scrollIntoView(
                        {
                          behavior: "smooth",
                          block: "center",
                          inline: "center",
                        },
                      )
                      this.setState({ ...this.state, selected: elm.id })


                    }

                  }
                }
                isOpen={elm.id === selected} title={elm.title} accordeonInnerBlocks={elm.innerBlocks}
                id={elm.id} isFirst={i === 0}/>
              )
            })
          }
        </div>
      </Fragment>
    )
  }
}

AccordeonBlock.propTypes = {}

AccordeonBlock.defaultProps = {}
export default AccordeonBlock
