import React from "react"
import styles from "./accordeonElement.module.css"
import Img from "gatsby-image"
import Responsive from "react-responsive"
import { Fragment } from "react"

const Desktop = props => <Responsive {...props} minWidth={979}/>
const Mobile = props => <Responsive {...props} maxWidth={978}/>


const AccordeonElement = ({ children, isOpen, title, accordeonInnerBlocks, isFirst, clickBlock, ...props }) => {

  return (

    <Fragment>
      <Mobile>
        <div id={props.id} onClick={clickBlock} className={`${styles.accordeonElementMobile} ${ isFirst ? styles.firstElement : ''}`}>
          <div className={`${styles.accordeonHeader} ${ isOpen ? styles.headerOpen : ''}`}>
            <h1 className={styles.headerTitleMobile}> {title}</h1>
            { isOpen ?  <i className={`${styles.angleDown} fa fa-angle-down`}></i>  :  <i className={`${styles.angleDown} fa fa-angle-down`}></i>  }
          </div>
          <div className={`${styles.accordeonBodyMobile} ${ isOpen ? styles.openMobile : styles.closedMobile} `}>
            {accordeonInnerBlocks.map(elm => {
              const leftContent = elm.descriptionListLeft
              const rightContent = elm.descriptionListRight
              let content = [];
              let elmStyles = styles.accordeonInnerBlock;
              if (leftContent && rightContent) {
                elmStyles = elmStyles + styles.fullWidth
              }
              else if (leftContent){
                elmStyles = elmStyles + '  ' + styles.leftInnerBlock;
                content = leftContent;
              }
              else if (rightContent){
                elmStyles = elmStyles + '  ' + styles.rightInnerBlock;
                content = rightContent;
              }

              return (
                <div className={`${elmStyles}`}>
                  <div className={`${styles.headerMobile}`}>
                    <div className={styles.logoContainerMobile}>
                      <Img fluid={elm.icon.fluid}/>
                    </div>
                    <h2 className={styles.titleText}> {elm.title} </h2>

                  </div>

                  {
                    leftContent && rightContent ?
                      (
                        <div className={styles.bodyContainerTwoColsMobile}>
                          <div className={styles.leftBody}>
                            {
                              leftContent.map(text=>{
                                return (
                                  <p className={styles.description}> {text} </p>
                                )
                              })
                            }
                            {
                              rightContent.map(text=>{
                                return (
                                  <p className={styles.description}> {text} </p>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                      :
                      (
                        <div className={styles.bodyContainerMobile}>
                          <div className={styles.body}>
                            {
                              content.map(text=>{
                                return (
                                  <p className={styles.description}> {text} </p>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                  }
                </div>
              )

            })}
          </div>

        </div>
      </Mobile>
      <Desktop>
        <div id={props.id} onClick={clickBlock} className={`${styles.accordeonElement} ${ isFirst ? styles.firstElement : ''}`}>
          <div className={`${styles.accordeonHeader} ${ isOpen ? styles.headerOpen : ''}`}>
            <h1 className={styles.headerTitle}> {title}</h1>
            { isOpen ? <i className={`${styles.angleDown} fa fa-angle-down`} aria-hidden="true"></i> :  <i className={`${styles.angleDown} fa fa-angle-up`} aria-hidden="true"></i> }
          </div>
          <div className={`${styles.accordeonBody} ${ isOpen ? styles.open : styles.closed} `}>
            {accordeonInnerBlocks.map(elm => {
              const leftContent = elm.descriptionListLeft
              const rightContent = elm.descriptionListRight
              let content = [];
              let elmStyles = styles.accordeonInnerBlock;
              if (leftContent && rightContent) {
                elmStyles = elmStyles + styles.fullWidth
              }
              else if (leftContent){
                elmStyles = elmStyles + ' ' + styles.halfWidth + ' ' + styles.leftInnerBlock;
                content = leftContent;
              }
              else if (rightContent){
                elmStyles = elmStyles + ' '  + styles.halfWidth + ' ' + styles.rightInnerBlock;
                content = rightContent;
              }

              return (
                <div className={`${elmStyles}`}>
                  <div className={`${styles.header}`}>
                    <div className={styles.logoContainer}>
                      <Img fluid={elm.icon.fluid}/>
                    </div>
                    <h2 className={styles.titleText}> {elm.title} </h2>

                  </div>

                  {
                    leftContent && rightContent ?
                      (
                        <div className={styles.bodyContainerTwoCols}>
                          <div className={styles.leftBody}>
                            {
                              leftContent.map(text=>{
                                return (
                                  <p className={styles.description}> {text} </p>
                                )
                              })
                            }

                          </div>
                          <div className={styles.rightBody}>
                            {
                              rightContent.map(text=>{
                                return (
                                  <p className={styles.description}> {text} </p>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                      :
                      (
                        <div className={styles.bodyContainer}>
                          <div className={styles.body}>
                            {
                              content.map(text=>{
                                return (
                                  <p className={styles.description}> {text} </p>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                  }
                </div>
              )

            })}
          </div>

        </div>
      </Desktop>


    </Fragment>
  )
}

AccordeonElement.propTypes = {}

AccordeonElement.defaultProps = {}
export default AccordeonElement
